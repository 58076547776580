/*!************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./node_modules/sass-loader/dist/cjs.js!./src/components/Utils.sass ***!
  \************************************************************************************************************************************************/
.Utils__match--BfCuc {
  color: #f8ffff;
  background-color: #4caccc;
}
/*!***********************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./node_modules/sass-loader/dist/cjs.js!./src/components/images/ImageShow.sass ***!
  \***********************************************************************************************************************************************************/
.ImageShow__container--kOUTI {
  border-radius: 6px;
  box-shadow: 2px 2px 8px #666;
}
/*!*************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./node_modules/sass-loader/dist/cjs.js!./src/components/images/ImagesField.sass ***!
  \*************************************************************************************************************************************************************/
.ImagesField__errorMessage--DI9Wy {
  color: red;
  margin: 4px;
}

.ImagesField__filesEdit--joOeI {
  display: flex;
  flex-flow: row wrap;
  width: 380px;
  margin-left: 10px;
}
.ImagesField__filesEdit--joOeI .ImagesField__file--Oc2pg {
  flex: 1 auto;
  width: 180px;
  height: 180px;
  position: relative;
  margin: 5px;
  margin-bottom: 32px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #444;
}
.ImagesField__filesEdit--joOeI .ImagesField__file--Oc2pg .ImagesField__controls--xPM6U {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.ImagesField__filesEdit--joOeI .ImagesField__file--Oc2pg .ImagesField__filePlaceholder--Cm4xW {
  display: block;
  font-size: 40px;
  text-align: center;
  line-height: 180px;
  border-radius: 5px;
  color: #aaa;
  text-shadow: -1px -1px 1px #555, 1px 1px 1px #ddd;
  box-shadow: 2px 2px 1px #888;
}
.ImagesField__filesEdit--joOeI .ImagesField__file--Oc2pg .ImagesField__fileName--z3fem {
  position: absolute;
  top: 100px;
  font-size: 11px;
  font-weight: bold;
  color: #222;
  width: 100%;
  text-shadow: 0 0 3px #aaa;
}

.ImagesField__fileProgress--WcAbv {
  color: black;
  font-size: 12px;
}
.ImagesField__fileProgress--WcAbv .ImagesField__reprogress--jslKK {
  display: none;
}

.ImagesField__dropzone--ZBGNq {
  border: 5px dashed gray;
  border-radius: 16px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.ImagesField__dropzone--ZBGNq.ImagesField__changing--jJcXC {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-color: #d54;
  border-radius: 5px;
}
.ImagesField__dropzone--ZBGNq.ImagesField__changing--jJcXC .ImagesField__icon--ZxJu3 {
  margin-top: -12px;
  color: #d54;
  text-shadow: 1px 1px 1px #222;
  opacity: 0.6;
}
.ImagesField__dropzone--ZBGNq .ImagesField__icon--ZxJu3 {
  display: block;
  font-size: 64px;
  color: gray;
  line-height: 200px;
  text-align: center;
  width: 100%;
}
.ImagesField__dropzone--ZBGNq.ImagesField__active--_2VAA {
  border-style: solid;
}
.ImagesField__dropzone--ZBGNq.ImagesField__accept--qx8A7 {
  border-color: green;
}

.ImagesField__pleaseDontDeleteMe--Bo9SP {
  margin: auto;
  margin-top: 10px;
}

/*# sourceMappingURL=pintor.css.map*/