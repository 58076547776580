$header-height: 48px;
$height: 34px;
$select-padding: 6px;
$select-total-height: $height + $select-padding;
$error-color: #711;

@mixin input-base {
  flex: 0 auto;
  border: 1px solid #a0a0a0;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 16px;
  width: 100%;
  &.errored {
    border: 1px solid $error-color; } }

.panel {
  display: block;
  margin-bottom: 8px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  overflow: hidden;
  .title {
    background-image: linear-gradient(#f0f0f0, #e0e0e0);
    border-bottom: 2px solid #a0a0a0;
    color: #202020;
    min-height: $header-height;
    line-height: $header-height;
    padding-left: 8px; }
  .content {
    background-image: linear-gradient(#f8f8f8, #e8e8e8); }
  .contentMargin {
    padding: 8px; } }

.inputBlockWrap {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 16px;
  .label {
    flex: 0 auto;
    margin-bottom: .25em; } }
.input {
  @include input-base;
  height: $height;
  &.standard {
    min-height: $height; } }

.textArea {
  @include input-base; }

.itemHeader {
  display: inline-block; }

.button {
  min-height: $height;
  height: $height;
  line-height: $height;
  padding: 0px 8px;
  border: 1px solid #b0b0b0;
  background-image: linear-gradient(#f0f0f0, #c0c0c0);
  border-radius: 4px;
  margin: 0 4px;
  cursor: pointer; }

.checkboxWrap {
  margin-bottom: 16px; }

.radioGroupWrap {
  margin-bottom: 16px; }

.checkboxLabel, .radioLabel {
  margin-left: 8px; }

.inputPanelWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.error {
  position: relative;
  font-size: 12px;
  color: $error-color;
  padding: 4px;
  .errorIcon {
    width: calc(100% - 8px);
    position: absolute;
    top: -26px;
    text-align: right;
    color: $error-color;
    pointer-events: none; } }

.select {
  height: $select-total-height - $select-padding;
  padding: $select-padding;
  border: 1px solid #b0b0b0;
  background-image: linear-gradient(#ffffff, #f8f8f8, #e0e0e0);
  border-radius: 4px; }
