.primary {
  color: #59d; }
.success {
  color: #6c6; }
.info {
  color: #5bd; }
.warning {
  color: #d93; }
.danger {
  color: #d55;
  border-color: #d43f3a; }
