$light: #f8f9fa;
$light-text: #505050;
$light-text-sel: darken($light-text, 30%);

$dark: #343a40;
$dark-text: #b0b0b0;
$dark-text-sel: lighten($dark-text, 30%);

.header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .right {
    float: right; }
  .brand {
    display: none; } }
.link {
  background-color: $light;
  color: $light-text;
  padding: .25rem .5rem !important;
  font-size: 14px;
  a, span {
    color: $light-text; }
  .innerLink {
    display: flex;
    flex-flow: column nowrap;
    .icon {
      flex: 1 auto;
      margin-right: none; }
    .text {
      flex: 1 auto;
      font-size: 10px; } }
  &.selected {
    color: $light-text-sel;
    background-color: darken($light, 10%);
    border-radius: 4px;
    a, span {
      color: $light-text-sel; } }
  button {
    background-color: $light;
    &:hover {
      background-color: $light; } }
  &.dark {
    background-color: $dark;
    &:hover {
      background-color: $dark; }
    button {
      &:hover {
        background-color: $dark; } }
    color: $dark-text;
    &.selected {
      color: $dark-text-sel;
      background-color: lighten($dark, 10%);
      a, span {
        color: $dark-text-sel; } }
    a, span {
      color: $dark-text; } } }

@media (min-width: 550px) {
  .header .brand {
    display: block; }
  .link {
    padding: .5rem 1rem !important;
    font-size: 16px;
    a {
      padding: 0; }
    .innerLink {
      flex-flow: row nowrap;
      .icon {
        margin-right: 6px; }
      .text {
        font-size: 16px; } } } }

