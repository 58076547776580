@import '../ui/shop.sass';

.wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
  .element {
    flex: 1 auto;
    margin: 2px;
    padding: 10px;
    height: $clickable-height;
    min-height: $clickable-height;
    line-height: 28px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 1px 1px 2px #888;
    text-align: center;
    .contents {
      display: flex;
      flex-flow: row wrap;
      .contentItem {
        flex: 1 auto; } } } }
