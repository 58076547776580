 :global {
  body {
    font-family: 'Lato', sans-serif;
    background-color: #f8f8f8;
    color: #202020; }
  a {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
    &:hover {
      color: inherit; } } }
