@import '../../ui/shop';

.pill {
  height: $clickable-height;
  min-height: $clickable-height; }
.ingredient {
  background-color: $ingredient-color-light;
  .icon {
    color: $ingredient-color-dark; }
  &.selected {
    background-color: #eeb; }
  white-space: nowrap; }
.add {
  background-color: #ada;
  .icon {
    color: #484; }
  &.selected {
    background-color: #beb; }
  .name {
    margin-right: 8px; } }
.match {
  color: lighten($ingredient-color-light, 10%);
  background-color: darken($ingredient-color, 4%); }
