$meal-color: #866;
$ingredient-color: #994;
$plus-color: #686;
$group-color: #688;

$highlight-background: #4caccc;
$highlight-color: #f8ffff;

$meal-color-dark: darken($meal-color, 30%);
$ingredient-color-dark: darken($ingredient-color, 15%);
$plus-color-dark: darken($plus-color, 15%);
$group-color-dark: darken($group-color, 30%);

$meal-color-light: lighten($meal-color, 30%);
$ingredient-color-light: lighten($ingredient-color, 30%);
$plus-color-light: lighten($plus-color, 40%);
$group-color-light: lighten($group-color, 30%);

$tag-color-match: #101060;

$clickable-height: 48px;

@mixin nav {
  display: block;
  text-decoration: none; }
